<template>
  <v-card flat class="pa-3 mt-2" :loading="cardLoading">
    <v-card-text>
      <v-form ref="houseStatusesForm" v-model="valid" class="multi-col-validation" @submit.prevent="handleFormSubmit">
        <v-row>
          <v-col cols="12">
            <masonry :cols="{ default: 3, 1620: 2, 1190: 1 }" :gutter="16">
              <div v-for="(status, index) in statuses" :id="`houseStatusCard_${index}`" :key="`${index}--category`">
                <v-card outlined class="my-4 card--border-primary">
                  <v-card-title>
                    <div v-if="!edit">{{ status.status }}</div>
                    <v-text-field
                      v-else
                      v-model="status.status"
                      outlined
                      dense
                      :label="$t('status')"
                      :placeholder="$t('status')"
                      :rules="[validators.required]"
                      maxlength="50"
                      hide-details="auto"
                    >
                      <template #append-outer>
                        <v-tooltip top transition="scroll-y-transition" open-delay="150">
                          <template #activator="{ on, attrs }">
                            <v-btn color="error" x-small v-bind="attrs" @click="removeStatus(index)" v-on="on">
                              <v-icon class="me-2" size="16">
                                {{ icons.mdiTrashCanOutline }}
                              </v-icon>
                              <span v-t="'delete'" />
                            </v-btn>
                          </template>
                          <span v-t="'delete'" class="text-xs" />
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-card-title>
                </v-card>
              </div>
              <v-btn v-if="edit" v-t="'addStatus'" color="primary" class="my-4" block @click="addStatus" />
            </masonry>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="!edit" cols="12"
            ><v-btn color="primary" @click.prevent="edit = !edit">
              <v-icon class="me-2">{{ icons.mdiPencilOutline }}</v-icon> <span v-t="'edit'" /></v-btn
          ></v-col>
          <v-col v-if="edit" cols="12">
            <v-btn color="primary" class="me-3" type="submit" :loading="loading" :disabled="!valid || !areDataChanged">
              {{ $t('save') }}
            </v-btn>
            <v-btn v-t="'discard'" outlined color="secondary" @click="restoreData" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash'
import { mdiPencilOutline, mdiTrashCanOutline } from '@mdi/js'
import { ref, onMounted, computed, nextTick } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { useActions } from 'vuex-composition-helpers'

export default {
  setup() {
    const houseStatusesForm = ref(null)
    const valid = ref(true)
    const loading = ref(false)
    const cardLoading = ref(false)
    const statuses = ref([])
    let frozenStatuses = {}
    const edit = ref(false)

    const { getHouseStatuses, updateHouseStatuses } = useActions('staff', ['getHouseStatuses', 'updateHouseStatuses'])

    onMounted(async () => {
      cardLoading.value = true
      statuses.value = await getHouseStatuses()
      frozenStatuses = Object.freeze(_.cloneDeep(statuses.value))
      cardLoading.value = false
    })

    const handleFormSubmit = async () => {
      const isFormValid = houseStatusesForm.value.validate()
      if (!isFormValid) return

      loading.value = true
      statuses.value = await updateHouseStatuses(statuses.value)
      frozenStatuses = Object.freeze(_.cloneDeep(statuses.value))
      loading.value = false
      edit.value = false
    }

    const restoreData = () => {
      statuses.value = _.cloneDeep(frozenStatuses)
      edit.value = false
    }

    const addStatus = async () => {
      statuses.value.push({
        status: '',
      })
      nextTick(() => {
        const el = document.getElementById(`houseStatusCard_${statuses.value.length - 1}`)
        el.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        })
      })
    }

    const removeStatus = (index) => {
      statuses.value.splice(index, 1)
    }

    const areDataChanged = computed(() => {
      return (
        !_.isEmpty(_.differenceWith(statuses.value, frozenStatuses, _.isEqual)) ||
        !_.isEmpty(_.differenceWith(frozenStatuses, statuses.value, _.isEqual))
      )
    })

    return {
      handleFormSubmit,
      restoreData,
      addStatus,
      removeStatus,

      areDataChanged,

      validators: {
        required,
      },
      houseStatusesForm,
      valid,
      loading,
      cardLoading,
      statuses,
      edit,
      icons: {
        mdiPencilOutline,
        mdiTrashCanOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
